import React from "react";
import Img from "gatsby-image";
const config = require("../../../data/SiteConfig")

class ThreeColumnCards extends React.Component {
  getPostList() {
    const postList = [];
    this.props.postEdges.forEach(postEdge => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        category: postEdge.node.frontmatter.category,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.fields.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead
      });
    });
    return postList;
  }

  render() {
    const postList = this.getPostList();
    return (
      <div>
        <div className="relative pt-12 pb-10 px-4 sm:px-6 lg:pt-10 lg:pb-10 lg:px-8">
        <div className="absolute inset-0">
            <div className="bg-white h-1/3 sm:h-2/3"></div>
        </div>
        <div className="relative max-w-7xl mx-auto">
            <div className="text-center">
            <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
                {config.blogTitle}
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 sm:mt-4">
                {config.blogDecription}
            </p>
            </div>
            <div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">


                {/* Your post list here. */
                postList.map(post => (
                <a href={post.path} key={post.title}>
                    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden min-h-full">
                        <div className="flex-shrink-0">
                            <img 
                                src={post.cover.childImageSharp.resize.src} 
                                width="100%" 
                                data-src={post.cover.childImageSharp.resize.src} 
                                className="lazyload h-48 w-full object-cover" 
                                alt={post.title}/>

                        </div>
                        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                        <div className="flex-1">
                            <p className="text-sm leading-5 font-medium text-indigo-600 capitalize">
                                {post.category}
                            </p>
                            <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                                {post.title}
                            </h3>
                            <p className="mt-3 text-base leading-6 text-gray-500">
                                {post.excerpt}
                            </p>
                        </div>

                        </div>
                    </div>

                </a>
                ))}


            </div>
        </div>
        </div>

        
      </div>
    );
  }
}

export default ThreeColumnCards;
