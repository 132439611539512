import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import ThreeColumnCards from "../components/BlogPostListing/ThreeColumnCards";


class Listing extends React.Component {
  renderPaging() {
    const { currentPageNum, pageCount } = this.props.pageContext;


    return (
      <div className="paging-container">
      <div className="pl-4 pr-4 py-3 flex items-center justify-between sm:px-6">
        <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <span className="relative z-0 inline-flex shadow-sm">
 
                {[...Array(pageCount)].map((_val, index) => {
                const pageNum = index + 1;
                return (
                    <a
                    href={pageNum === 1 ? `/${config.blogDirectory}` : `/${config.blogDirectory}/${pageNum}`}
                    >
                    <button type="button" className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                        {pageNum}
                    </button>            
                    </a>
                );
                })}              

            </span>
          </div>
        </div>
      </div>      
      </div>
    );
  }

  render() {
    const postEdges = this.props.data.allMarkdownRemark.edges;

    return (
      <Layout>
        <div className="listing-container">
          <div className="posts-container">
            <Helmet title={config.siteTitle} />
            <SEO />
            <ThreeColumnCards postEdges={postEdges} />
          </div>
          <div class="max-w-7xl mx-auto sm:px-6 lg:px-2 pb-10">
          {this.renderPaging()}
          </div>
        </div>
      </Layout>
    );
  }
}

export default Listing;

/* eslint no-undef: "off" */
export const listingQuery = graphql`
  query ListingQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover {
              childImageSharp {
                resize(width: 400) {
                    width
                    height
                    src
                }
              }
            }
            date
            category
          }
        }
      }
    }
  }
`;
